<template>
  <div class="shadow-sm rounded py-3 ps-4 pe-2 mt-2" v-if="!isAuthenticated">  
    <h1>Мои курсы и плейлисты</h1>
    <p>Войдите в личный кабинет, чтобы продолжить.</p>
    <telegram-auth v-if="!configData.disable_telegram_auth"></telegram-auth>
  </div>
  
  <div class="pt-4" v-if="isAuthenticated">
   <h1>Мои курсы и плейлисты</h1>

<!--     <div class="alert alert-warning" v-if="!channels.length">
      Создайте канал
    </div>
 -->
  <ul class="nav nav-tabs mb-4 mt-3">
    <li class="nav-item">
      <button class="nav-link" :class="{ active: typeTab === 'courses' }" @click="changeTypeTab('courses')">Курсы</button>
    </li>              

    <li class="nav-item">
      <button class="nav-link" :class="{ active: typeTab === 'collections' }" @click="changeTypeTab('collections')">Плейлисты</button>
    </li>              
    <li class="nav-item">
      <button class="nav-link" :class="{ active: typeTab === 'categories' }" @click="changeTypeTab('categories')">Категории</button>
    </li>

  </ul>
    
  
  <div v-if="typeTab === 'collections' || typeTab == 'courses'">
    
    <div class="alert alert-info" v-if="typeTab === 'collections'">Вы можете объединить свои материалы в плейлисты с бесплатным доступом.</div>

    <div class="alert alert-info" v-if="typeTab === 'courses'">Вы можете создать из своих материалов курс, задав критерии открытия материалов по результатам за предыдущий тест, дате или за оплату.</div>

    <button @click="showAddEditCollectionModal()" class="btn btn-primary" v-if="typeTab === 'collections'">Создать плейлист</button>
    <button @click="showAddEditCollectionModal()" class="btn btn-primary" v-if="typeTab === 'courses'">Создать курс</button>

    <spinner-component :visible="listLoading" class="mt-4"></spinner-component>


    <ul class="list-group mt-3">
      <draggable
          :list="collections"
          class="list-group"
          item-key="priority"
          @end="onDragEnd"
        >
      <li v-for="(collection) in collections" :key="collection.id" class="list-group-item d-flex justify-content-between align-items-start">
        <div class="d-flex align-items-start w-100">
          <div class="" v-if="collection.thumbs.small"><img :src="collection.thumbs.small" alt="" class="thumb me-2"></div>
          <div class="thumb empty-thumb" v-if="!collection.thumbs.small"><i class="bi bi-camera"></i></div>
          <div class="d-flex flex-column flex-md-row justify-content-between w-100">
            <div class="mb-1 mb-md-0">
              <h4 class="mb-0">
                <router-link :to="{ name: 'Collection', params: { id: collection.hash } }">
                  {{ collection.caption }}
                </router-link>
              </h4>
              <p v-if="collection.materials_count" class="mb-0">{{ collection.materials_count }} материалов</p>
              <p v-if="!collection.materials_count" class="mb-0">нет материалов</p>
            </div>
            <div class="d-flex align-items-start">
              <div class="me-2">
                <select v-model="collection.channel_id" class="form-select" @change="handleSelectChange(collection)">
                  <option :value="0">Без канала</option>
                  <option v-for="channelOption in channels" :key="channelOption.id" :value="channelOption.id">Канал: {{ channelOption.caption }}</option>
                  <option :value="'addChannel'">+Создать канал</option>
                </select>
              </div>
            </div>
          </div>


        </div>
        <div class="collection-actions text-nowrap">
          <button @click="showAddEditCollectionModal(collection)" class="btn btn-primary">
            <i class="bi bi-pencil"></i>
          </button>

          <button @click="toggleVisibility(collection)" class="btn ms-2" :class="(collection.visibility)?'btn-success':'btn-warning'">
            <i class="bi bi-eye" v-if="collection.visibility == 1"></i>
            <i class="bi bi-eye-slash" v-if="collection.visibility == 0"></i>
          </button>

          <button @click="confirmDelete(collection)" class="btn btn-danger ms-2">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </li>
        </draggable>
    </ul>

      


  </div>
   <div v-if="typeTab === 'categories'">
    <div class="alert alert-info" v-if="typeTab === 'categories'">Вы можете создать категории для ваших материалов в канале.</div>    
    <button @click="showAddEditCollectionModal()" class="btn btn-primary">Создать категорию</button>
    <spinner-component :visible="listLoading" class="mt-4"></spinner-component>

    <ul class="list-group mt-3">
       <draggable
          :list="collections"
          class="list-group"
          item-key="priority"
          @end="onDragEnd"
        >
      <li v-for="(collection) in collections" :key="collection.id" class="list-group-item d-flex justify-content-between align-items-center">

        <div class="d-flex justify-content-between w-100">
          <div class="flex-grow">{{ collection.caption }}</div>

          <div class="d-flex">
            <select v-model="collection.channel_id" class="form-select me-2" @change="saveCollectionChannel(collection)">
              <option :value="0">Без канала</option>
              <option v-for="channelOption in channels" :key="channelOption.id" :value="channelOption.id">Канал: {{ channelOption.caption }}</option>
            </select>

            <div class="collection-actions text-nowrap">

              <button @click="showAddEditCollectionModal(collection)" class="btn btn-primary">
                <i class="bi bi-pencil"></i>
              </button>
              <button @click="confirmDelete(collection)" class="btn btn-danger ms-2">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </li>
    </draggable>
    </ul>
    
  </div>

<!--   <div class="mt-3 alert alert-info">
    <p>Добавить новые материалы вы можете через чат-бот. Просто добавьте видео или аудио на преобразование в текст. После обработки тут вы сможете отредактировать текст материала, а также тест к нему при необходимости.</p>
    <a class="btn btn-primary" href="https://t.me/vid2text_bot">Перейти в чат-бот</a>
  </div> -->

    <!-- Modal window for creating a collection -->
    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showCreateModal }">
      <div class="modal-dialog modal-lg modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">{{ collectionItem.id ? 'Редактировать курс или плейлист' : 'Создать курс или плейлист' }}</h2>
            <button type="button" class="btn btn-light" @click="cancelCollection">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs mb-4" v-if="collectionItem.id">
              <li class="nav-item">
                <button class="nav-link" :class="{ active: activeTab === 'settings' }" @click="activeTab = 'settings'">Настройки</button>
              </li>              
              <li class="nav-item">
                <button class="nav-link" :class="{ active: activeTab === 'materials' }" @click="activeTab = 'materials'">Материалы</button>
              </li>

               <li class="nav-item" v-if="collectionItem.type_tab == 'courses' && collectionItem.id">
                <button class="nav-link" :class="{ active: activeTab === 'telegram_config' }" @click="activeTab = 'telegram_config'">Telegram-бот и продажа</button>
              </li>

              <li class="nav-item" v-if="collectionItem.type_tab == 'courses' && 0">
                <button class="nav-link" :class="{ active: activeTab === 'students' }" @click="activeTab = 'students'">Слушатели</button>
              </li>

               <li class="nav-item" v-if="collectionItem.type_tab == 'courses' && 0">
                <button class="nav-link" :class="{ active: activeTab === 'curators' }" @click="activeTab = 'curators'">Кураторы</button>
              </li>

            </ul>
            <div v-if="activeTab === 'materials'" class="tab-content">

              <MaterialSelectorComponent :collectionId="collectionItem.id" :collectionType="collectionItem.type_id"/>
            </div>
            <div v-if="activeTab === 'students'" class="tab-content">

              <CollectionStudentsComponent :collectionId="collectionItem.id" :usersType="'students'" v-if="activeTab === 'students' && collectionItem.id"/>
            </div>

            <div v-if="activeTab === 'curators'" class="tab-content">
              <CollectionStudentsComponent :collectionId="collectionItem.id" :usersType="'curators'"  v-if="activeTab === 'curators' && collectionItem.id"/>
            </div>

            <div v-if="activeTab === 'telegram_config'" class="tab-content">
              <form>

                <div v-if='collectionItem.bot_username' class="mb-2">

                  <div class="form-group mb-2">
                    <label for="bot_username">Название бота</label>
                    <input type="text" id="bot_username" class="form-control" :disabled="true" v-model="collectionItem.bot_username" required>
                  </div>

                  <div class="" v-if="collectionItem.tariff.tariff == 'Pro'">
                    
                    <div class="form-group mb-2">
                      <label for="price">Стоимость курса</label>
                      <input type="text" id="price" class="form-control" v-model="collectionItem.price" required>
                    </div>


                    <h4>Интеграция с «ЮКасса» </h4>
                    <div class="text text-info">Если вам нужна помощь с подключением – напишите <a href="https://t.me/ayarmots">@AYarmots</a></div> 
                    <div class="form-group mb-2">
                      <label for="tg_bot_payment_token">Платежный токен</label>
                      <input type="text" id="tg_bot_payment_token" class="form-control" v-model="collectionItem.tg_bot_payment_token" required>
                    </div>

                    <h4>Интеграция с Тинькофф-эквайринг</h4>
                    <div class="text text-info">Если вам нужна помощь с подключением – напишите <a href="https://t.me/ayarmots">@AYarmots</a></div> 
                    <div class="form-group mb-2">
                      <label for="terminal_key">Terminal Key <span v-if="collectionItem.tinkoff_payments_on" class="text text-success small">ключ введен и скрыт в целях безопасности</span></label>
                      <input type="text" id="terminal_key" class="form-control" v-model="collectionItem.terminal_key" required>
                    </div>
                    <div class="form-group mb-2">
                      <label for="secret_key">Secret Key  <span v-if="collectionItem.tinkoff_payments_on" class="text text-success small">ключ введен и скрыт в целях безопасности</span></label>
                      <input type="text" id="secret_key" class="form-control" v-model="collectionItem.secret_key" required>
                    </div>
                  </div>
                  <div class="" v-if="collectionItem.tariff.tariff == 'Free'">
                    <h4>Настройте прием платежей</h4>
                    <div>Требуется повысить тариф в этом курсе, чтобы активировать прием платежей.</div>
                    <a :href="collectionItem.tariff.tg_upgrade_tariff_link" target="_blank" class="btn btn-primary">Активировать платежи</a>
                  </div>
                </div>

                <div class="form-group mb-2">
                  <h4>Подключение курса к telegram-боту</h4>
                  <label for="tg_bot_token_new">Укажите Token телеграм-бота</label>
                  <div v-if="collectionItem.bot_username"><span class="text-warning">Если хотите подключить курс к другому чат-боту.</span></div>
                  <input type="text" id="tg_bot_token_new" class="form-control" v-model="collectionItem.tg_bot_token_new" required>
                </div>
                
                <div class="mt-1 d-flex justify-content-between align-items-center">
                  <button type="button" class="btn btn-primary" @click="saveCollection">Сохранить</button>
                  <button type="button" class="btn btn-secondary" @click="cancelCollection">
                  {{ editingCollectionId ? 'Отмена' : 'Закрыть' }}
                  </button>
                </div>

              </form>
            </div>


            <div v-if="activeTab === 'settings'" class="tab-content">
              <form>


                <div class="form-group mb-2">
                  <label for="title">Название</label>
                  <input type="text" id="caption" class="form-control" v-model="collectionItem.caption" required>
                </div>
                
                <div class="form-group mb-2">
                  <label for="channel" class="">Тип:</label>
                  <select class="form-control" v-model="collectionItem.type_tab">
                    <option value="courses">Курс</option>                    
                    <option value="collections">Плейлист</option>
                    <option value="categories">Категория</option>
                  </select>
                </div>

                <div class="form-group mb-2">
                  <label for="channel" class="">Выберите канал (необязательно):</label>
                  <select class="form-control" v-model="collectionItem.channel_id">
                    <option value="">Выберите канал</option>
                    <option v-for="channel in channels" :key="channel.id" :value="channel.id">{{ channel.caption }}</option>
                  </select>
                </div>


                <div v-if="collectionItem.type_tab == 'courses' && 0">
                  
                  <div class="form-group mb-2">
                    <label for="channel" class="">Подключите Telegram-группу курса</label>
                    <div class="alert alert-info" v-if="collectionItem.tg_group_id == 0">
                      Добавьте чат-бота <a href="https://t.me/YouTestRu_bot">@YouTestRu_bot</a> в группу курса. Вы должны быть владельцем этой группы.
                    </div>
                    <div class="list-group">
                      <div class="list-group-item d-flex justify-content-between" v-if="collectionItem.tg_group_id !== 0 ">
                        {{collectionItem.tg_group_caption}}
                         <a href="#" class="btn btn-danger btn-sm" @click="deleteCollectionGroup(collectionItem)">Отменить связь</a>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div v-if="collectionItem.type_id !== '2'">
                  <div class="form-group mb-2">
                    <label for="description">Описание</label>
                    <!-- <textarea id="description" class="form-control" v-model="collectionItem.description"></textarea> -->
                    <div  class="editor-container">

                      <QuillEditor
                        theme="snow"
                        v-model:content="collectionItem.description"
                        contentType="html"
                        :toolbar="[
                          { 'header': [2, 3, 4, false] },
                          'bold',
                          'italic',
                          'underline',
                          'blockquote',
                          { list: 'ordered' },
                          { list: 'bullet' },
                          'clean',
                          'html'
                        ]"
                      />
                    </div>
                  </div>




                  <div v-if="collectionItem.id">
                    <div class="form-group mb-2" v-if="collectionItem.thumbs && collectionItem.thumbs.big">
                      <label for="coverImage">Обложка</label>
                      <div><img :src="collectionItem.thumbs.big" class="cover"/></div>
                    </div>


                    <spinner-component :visible="coverUploading"></spinner-component>
                    <div class="form-group mb-2">
                      <label for="coverImage">Загрузить обложку (рекомендуемый размер 1920x1080)</label>
                      
                      <div><input type="file" id="coverImage" @change="handleCoverImageChange" accept="image/*"></div>
                    </div>
                  </div>


                </div>

                <div class="mt-1 d-flex justify-content-between align-items-center">
                  <button type="button" class="btn btn-primary" @click="saveCollection">{{ collectionItem.id ? 'Сохранить' : 'Создать' }}</button>
                  <button type="button" class="btn btn-secondary" @click="cancelCollection">
                  {{ editingCollectionId ? 'Отмена' : 'Закрыть' }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <overlay-component :visible="showCreateModal"></overlay-component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, useStore } from 'vuex';
import { computed } from 'vue';
import { useToast } from "vue-toastification";
import OverlayComponent from '@/components/OverlayComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import MaterialSelectorComponent from '@/components/MaterialSelectorComponent.vue';
import CollectionStudentsComponent from '@/components/CollectionStudentsComponent.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { QuillEditor } from '@vueup/vue-quill';
import TelegramAuth from './TelegramAuth.vue';

export default {
  data() {
    return {
      editingCollectionId: null,
      selectedChannelId: null,
      activeTab: 'settings',
      typeTab: 'courses',
      showCreateModal: false,
      coverUploading: false,
      listLoading: false,
    };
  },
  components: {
    OverlayComponent,
    SpinnerComponent,
    MaterialSelectorComponent,
    draggable: VueDraggableNext,
    QuillEditor,
    CollectionStudentsComponent,
    TelegramAuth,
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'chatbotName']),
    ...mapGetters('channel', ['getChannelItem', 'getChannels']),
    ...mapGetters('collection', ['getCollectionItem', 'getCollections']),
    ...mapMutations('channel', ['setChannelItem', 'setChannels']),
    collectionItem: {
      get() {
        return this.getCollectionItem || {};
      },
      set(value) {
        this.updateCollectionItem(value);
      },
    },
    collections: {
      get() {
        return this.getCollections || [];
      },
      set(value) {
        this.$store.commit('collection/setCollections', value);
      },
    },
    channels: {
      get() {
        return this.getChannels || [];
      },
      set(value) {
        this.$store.commit('channel/setChannels', value);
      },
    },
  },
  watch: {
    'collectionItem.tg_group_id'(newVal) {
      console.log(newVal, this.collectionTgGroupInterval);
      if (newVal !== 0 && this.collectionTgGroupInterval) {
        clearInterval(this.collectionTgGroupInterval); // Stop the interval
      }
    },
  },
  created() {
    // Вызов экшена для загрузки подборки
    this.fetchCollections();
    this.fetchChannels();

    // Проверка параметров URL и установка начальных значений
    const urlParams = new URLSearchParams(window.location.search);
    const collectionId = urlParams.get('collectionId');
    const tab = urlParams.get('tab');

    if (collectionId) {
      this.fetchCollection(collectionId).then((data) => {
        this.activeTab = 'settings';
        this.editCollection(data.collection);
      });
    }

    if (tab) {
      this.activeTab = tab;
    }
  },
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    return {
      isAuthenticated,
    };
  },
  methods: {
    ...mapMutations('collection', ['setCollectionItem', 'setCollections']),
    ...mapActions('collection', ['fetchCollection', 'saveCollection', 'deleteCollection', 'editMaterial', 'deleteMaterial', 'getMaterialsForCollection', 'fetchCollections', 'saveCollectionCoverImage']),
    ...mapActions('channel', ['fetchChannels']),
    startTGGroupInterval() {
      console.log('start interval', this.collectionItem.tg_group_id);
      if (this.collectionItem && this.collectionItem.tg_group_id == 0) {
        this.collectionTgGroupInterval = setInterval(() => {
          this.$store.dispatch('collection/fetchCollectionTgGroup', this.collectionItem.id);
        }, 5000);
      }
    },
    editCollection(collection) {
      // Установка ID редактируемой подборки и заполнение полей формы данными
      this.editingCollectionId = collection.id;
      // Вызов мутации `setCollectionItem` из модуля `collection`
      this.setCollectionItem(collection);
      // Отображение модального окна для редактирования подборки
      this.showCreateModal = true;
    },
    confirmDelete(collection) {
      if (window.confirm('Подтверждаете удаление?')) {
        this.deleteCollection(collection);
      }
    },
    changeTypeTab(typeTab) {
      this.collections = [];
      this.typeTab = typeTab;
      this.fetchCollections();
    },
    async handleCoverImageChange(event) {
      const file = event.target.files[0];
      this.coverUploading = true;
      if (!file) return; // Если файл не выбран, ничего не делаем
      const formData = new FormData();
      formData.append('coverImage', file);
      try {
        const response = await this.saveCollectionCoverImage({
          collectionId: this.collectionItem.id,
          coverImageForm: formData,
        });
        this.setCollectionItem(response.collection);
        this.coverUploading = false;
      } catch (error) {
        this.coverUploading = false;
        console.error('Error uploading cover image:', error);
      }
    },
    onDragEnd(event) {
      console.log(event);
      console.log(this.collections);
      var collections = this.collections;
      this.$store.commit('collection/updateCollectionsPriority', {
        collections: collections,
      });
    },
    deleteCollection(collection) {
      this.setCollectionItem(collection);
      this.$store.dispatch('collection/deleteCollection', collection)
        .then((data) => {
          console.log(data);
          const toast = useToast();
          toast.success('Плейлист удален', {
            timeout: 2000,
          });
          const index = this.collections.findIndex(coll => coll.id === collection.id);
          if (index !== -1) {
            this.collections.splice(index, 1);
          }
          if (this.collectionItem && this.collectionItem.id === collection.id) {
            this.setCollectionItem(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      console.log('Collection deleted successfully');
    },
    fetchCollections() {
      console.log(this.typeTab);
      this.listLoading = true;
      this.setCollections([]);
      this.$store.dispatch('collection/fetchCollections', { channelId: this.selectedChannelId, typeTab: this.typeTab })
        .then((data) => {
          console.log(data);
          this.setCollections(data.collections);
          this.listLoading = false;
        })
        .catch((error) => {
          this.listLoading = false;
          console.log(error);
        });
    },
    async toggleVisibility(collection) {
      collection.visibility = collection.visibility == 0 ? 1 : 0;
      console.log(collection.visibility);
      const updatedCollection = {
        id: collection.id,
        visibility: collection.visibility,
      };
      try {
        await this.$store.dispatch('collection/saveCollection', updatedCollection);
        const toast = useToast();
        toast.success(collection.visibility == 0 ? 'Список скрыт' : 'Скисок видят все', {
          timeout: 2000,
        });
      } catch (error) {
        console.log(error);
      }
    },
    showAddEditCollectionModal(collection = null) {
      this.collectionLoading = true;
      if (collection && typeof collection.id !== 'undefined') {
        this.$store.dispatch('collection/fetchCollection', collection.id)
          .then((data) => {
            collection = data.collection;
            this.collectionLoading = false;
            this.activeTab = 'settings';
            if (collection) {
              this.$store.dispatch('collection/setActiveCollection', { collectionId: collection.id });
              this.startTGGroupInterval;
              this.setCollectionItem(collection);
            } else {
              var col = { channel_id: this.selectedChannelId, 'type_tab': this.typeTab };
              this.setCollectionItem(col);
              this.editingCollectionId = null;
            }
            this.$store.dispatch('material/clearCollectionMaterials');
            this.showCreateModal = true;
          })
          .catch((error) => {
            this.collectionLoading = false;
            console.log(error);
          });
      } else {
        this.showCreateModal = true;
      }
    },
    async saveCollectionChannel(collection) {
      this.setCollectionItem(collection);
      const updatedCollection = {
        id: this.collectionItem.id,
        caption: this.collectionItem.caption,
        description: this.collectionItem.description,
        channel_id: this.collectionItem.channel_id,
      };
      try {
        // eslint-disable-next-line
        const data = await this.$store.dispatch('collection/saveCollection', updatedCollection);
        const toast = useToast();
        toast.success('Канал плейлиста изменен', {
          timeout: 2000,
        });
      } catch (error) {
        console.log(error);
      }
    },
    deleteCollectionGroup(collection) {
      this.$store.dispatch('collection/deleteCollectionTgGroup', collection.id)
        .then((data) => {
          this.startTGGroupInterval;
          console.log(data);
        });
    },
    handleSelectChange(collection) {
      if (collection.channel_id !== 'addChannel') {
        this.saveCollectionChannel(collection);
      } else if (collection.channel_id == 'addChannel') {
        this.$router.push('/channels');
      }
    },
    async saveCollection() {
      const addNew = typeof this.collectionItem.id == 'undefined';
      const updatedCollection = {
        id: this.collectionItem.id,
        caption: this.collectionItem.caption,
        description: this.collectionItem.description,
        channel_id: this.collectionItem.channel_id,
        type_tab: this.collectionItem.type_tab,
        item: this.collectionItem,
      };
      try {
        // eslint-disable-next-line
        const data = await this.$store.dispatch('collection/saveCollection', updatedCollection);
        const toast = useToast();
        toast.success('Плейлист сохранен', {
          timeout: 2000,
        });
        let index = this.collections.findIndex(collection => collection.id === this.collectionItem.id);
        if (index !== -1) {
          this.collections[index] = this.collectionItem;
        }
        if (addNew) this.collections.unshift(this.collectionItem);
      } catch (error) {
        console.log(error);
      }
    },
    cancelCollection() {
      this.showCreateModal = false;
      this.setCollectionItem(null);
    },
    selectFromMyMaterials() {
      // Perform the action when selecting from my materials
    }
  }
};
</script>

<style>
/* Component styles here */
.cover{
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.thumb{
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 6px;
}

.empty-thumb{
  background-color: #EFEFEF;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
